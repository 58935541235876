import { ContactUsState, ContactUsActionTypes, OPEN, CLOSE } from '../types/contactUs';

const initialState: ContactUsState = {
  show: false
};

export const contactUsReducer = (
  state = initialState,
  action: ContactUsActionTypes
): ContactUsState => {
  switch (action.type) {
    case OPEN:
      return Object.assign({}, state, {show: true});
    case CLOSE:
      return Object.assign({}, state, {show: false});
    default:
      return state;
  }
};