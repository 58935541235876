import { ErrorStateType } from '../../types/errors'; 

export interface ErrorState {
  errors: ErrorStateType[]
}

export const SET_ERROR = 'SET_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

interface SetErrorAction {
  type: typeof SET_ERROR,
  payload: ErrorStateType
}

interface RemoveErrorAction {
  type: typeof REMOVE_ERROR,
  payload: ErrorStateType
}

interface ClearErrorsAction {
  type: typeof CLEAR_ERRORS,
  payload: undefined
}

export type ErrorActionTypes = SetErrorAction | RemoveErrorAction | ClearErrorsAction;