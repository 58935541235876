import produce, {Draft} from 'immer';

import {
  ICustomMessagesState,
  MARKETING_MESSAGE,
  NOTIFICATION,
  CustomMessageActionTypes,
  HELP_CONTENT
} from '../types/customMessage';

const initialState: ICustomMessagesState = {
  marketing: '',
  notification: undefined,
  help: ''
};

export const customMessagesReducer = produce((
  state: Draft<ICustomMessagesState>,
  action: CustomMessageActionTypes
): void => {
  switch (action.type) {
    case MARKETING_MESSAGE:
      state.marketing = action.payload as string;
      break;
    case NOTIFICATION:
      state.notification = action.payload as string;
      break;
    case HELP_CONTENT:
      state.help = action.payload as string;
      break;
  }
}, initialState);
