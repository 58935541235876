import * as React from 'react';

import Iframe from "react-iframe";

export interface IStaticIFrameStateProps {
    url: string
}

const StaticIFrame: React.FC<IStaticIFrameStateProps> = ({ url }) => {
    console.log(url);

    return (
        <div className="page-container">
            <Iframe
                url={url}
                width="100%"
                height="750px" />
        </div>
    );
};


export default StaticIFrame;