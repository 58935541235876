import produce, {Draft} from 'immer';

import { INITIAL_PAYLOAD, IInitialPayloadState, IInitialPayloadAction } from '../types/initialPayload';

const initialState: IInitialPayloadState = {
  fetched: false
};

export const initialPayloadReducer = produce((
  state: Draft<IInitialPayloadState>,
  action: IInitialPayloadAction
): void => {
  switch (action.type) {
    case INITIAL_PAYLOAD:
      state.fetched = action.payload;
      break;
  }
}, initialState);
