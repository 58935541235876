import { Action } from 'redux';

export const SET_COOKIE_POLICY_HTML = 'COOKIE_POLICY_HTML_SET';

export interface IActionSetCookiePolicyHtml extends Action {
  payload: string
}

export type CookiePolicyActionsType = IActionSetCookiePolicyHtml;

export interface ICookiePolicyState {
  html: string
}