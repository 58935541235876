export interface ContactUsState {
  show: boolean
}

export const OPEN = 'OPEN';
export const CLOSE = 'CLOSE';

interface OpenAction {
  type: typeof OPEN,
  payload: undefined
}

interface CloseAction {
  type: typeof CLOSE,
  payload: undefined
}

export type ContactUsActionTypes = OpenAction | CloseAction;

