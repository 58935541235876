import { OPEN, CLOSE } from '../types/contactUs';

export const open = () => ({
  type: OPEN,
  payload: undefined
});

export const close = () => ({
  type: CLOSE,
  payload: undefined
});
