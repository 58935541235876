import { connect } from 'react-redux';
import i18n from 'i18next';

import Footer, { FooterDispatchProps, IFooterStateProps } from '../components/footer/Footer';
import { setLanguage } from '../library/locale';
import { IApplicationState } from '../redux';

interface IMapStateToProps extends Pick<IApplicationState, 'locales'> {}

const mapStateToProps = ({locales }: IMapStateToProps): IFooterStateProps => ({
  lng: i18n.language,
  lngs: locales.lngs
});

const mapDispatchToProps = (dispatch: any): FooterDispatchProps => ({
  setLanguage: (lng: string) => setLanguage(lng)
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);