/* eslint-disable max-len */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getLanguage } from './library/localStorage';

import en from './locale/en-US.json';
import esPY from './locale/es-PY.json';

export const fallbackLanguage = 'en-US';

export const interpolationFormat = (value: any, format?: string, lng?: string): string => {
  if (format === 'date') return new Intl.DateTimeFormat(lng).format(new Date(value));
  if (format === 'datetime') return Intl.DateTimeFormat(lng, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }).format(new Date(value)).replace(',', '');
  return value;
};

i18n
  .use(initReactI18next)
  .use({
    type: 'postProcessor',
    name: 'uppercase',
    process: (value: string, key: string, options: any, translator: any) => {
      if (options?.uppercase) {
        return value.toUpperCase();
      }
      return value;
    }
  })
  .init({
    fallbackLng: fallbackLanguage,
    debug: false,
    resources: {
      'en-US': {
        translations: en
      },
      'es-PY': {
        translations: esPY
      }
    },
    lng: getLanguage() || fallbackLanguage,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: '.', // we use content as keys
    react: {
      useSuspense: false
    },
    postProcess: ['uppercase'],
    interpolation: {
      format: interpolationFormat,
      escapeValue: false
    }
  });

export default i18n;