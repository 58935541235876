import * as React from 'react';
import StaticHtml from "../staticHtml/staticHtml";

export interface IAboutStateProps {

}

const About: React.FC<IAboutStateProps> = () => {
    return (
        <StaticHtml
            key="about"
            path="/pages/about.html"/>
    );
};


export default About;