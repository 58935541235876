import { Action } from 'redux';

import { IActionString } from './common';
import { INotification } from '../../types/notification';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export interface IAddNotificationAction extends Action {
  payload: INotification
}

export type NotificationsActionsType = IActionString | Action;

export interface INotificationsState {
  notifications: INotification[]
}