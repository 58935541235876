import * as React from 'react';
import StaticHtml from "../staticHtml/staticHtml";

export interface IDocumentationStateProps {

}

const Documentation: React.FC<IDocumentationStateProps> = () => {
    return (
        <StaticHtml
            key="documentation"
            path="/pages/documentation.html"/>
    );
};


export default Documentation;