/* eslint-disable max-len, indent */
import * as React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div>
<h2 className='text-center'>Adaptive Agate Terms of Use</h2>
<p className="text-center font-family-heading">Effective Date: January 4, 2021</p>
<p></p>
<strong>IMPORTANT: Read these Terms of Service (&quot;Terms&quot;) carefully as they govern the use by you and others acting on your behalf (collectively, &quot;you&quot;) of this Adaptive Agate online software platform (&quot;Platform&quot;). </strong><br /><br />
<strong>1. Binding Agreement</strong><br />By doing any of the following, you agree to the Terms:<br />
- accepting an invitation to join a project on the Platform; or, <br />
- as to COVID-19 Data (defined below) from our immuneRACE COVID-19 study, accessing such Data.<br /><br />
<strong>2. Use and Access</strong>
<br />(a) <u>Permitted Uses.</u><br />
(i) Adaptive Biotechnologies Corporation (&quot;<strong>we</strong>&quot; or &quot;<strong>us</strong>&quot;) owns this Platform. We make the Platform available solely for authorized users to upload and view sequence data generated by us through our mail-in research services, through authorized use of our research assay kits and reagents by third parties (such as distributors and research organizations)  (collectively, &quot;<strong>Data</strong>&quot;) or under the separate terms and conditions applicable to results of our immuneRACE COVID-19 study (&quot;<strong>COVID-19 Data</strong>&quot;).  <strong>Submission of any material to the Platform from any other source is strictly prohibited.</strong>
<br /><br />(ii)  <strong>Data are supplied to you solely for personal, research-only use. Use of COVID-19 Data is subject to the license grant and other terms and conditions (&quot;IR Terms&quot;) set forth at <a href="https://immunerace.adaptivebiotech.com/data-terms-of-use" target="_blank">https://immunerace.adaptivebiotech.com/data-terms-of-use</a>.  In the event of a conflict between these Terms and the IR Terms, the latter shall control as to use of COVID-19 Data.</strong>
<br /><br />(iii)  By accessing the Platform, you represent that you have been appropriately authorized to use it and either the Data or the COVID-19 Data by us or by someone under contract with us. You also agree to cease using the Platform if your authorization to do so is terminated. 
<br /><br />
(b) <u>Internet Access.</u> The Platform is offered on an &quot;as available&quot; basis and we do not make specific service level commitments including, without limitation, with respect to the availability of an internet connection or security of data transmitted across networks or to software programs which we do not control. The Platform is hosted on our servers, and we may update its functionality and documentation at any time without notice to you.
<br /><br />
(c) <u>Passwords;  Authorized Users.</u> Credentials for accessing Data on the Platform cannot be used by more than one user, but may be reassigned to another user within the same business, institution or other entity as needed. You are responsible for all uses of your user name and password. You agree to prevent unauthorized access to or use of the Platform and to promptly notify us of any loss or theft or unauthorized use of your access credentials. If you are a business, institution or other entity, you agree to ensure that all access to and use of the Platform by your authorized users is in accordance with these Terms, including users that are affiliates, employees, contractors and agents, and that any action or breach by any of such affiliates, employees, contractors and agents will be deemed an action or breach by you. 
<br /><br />
(d)  <u>Your Legal Obligations.</u> In connection with any upload of Data to the Platform by you or on your behalf, you represent and warrant that (i) the submission of Data by you or on your behalf does not breach or conflict with any obligation which you may have to a third party; and (ii) you have all rights to the Data necessary to permit its submission to the Platform. You and those acting on your behalf agree to comply with all applicable laws and regulations in connection with the Platform.
<br /><br />
(e) <u>HIPAA.</u>  You and those acting on your behalf agree that, in providing you with access to Data on the Platform, we are not acting on your behalf as a business associate or subcontractor within the meaning of the Health Insurance Portability and Accountability Act (&quot;<strong>HIPAA</strong>&quot;), and you promise not to submit any protected health information (within the meaning of HIPAA) or otherwise use the Platform in any manner that would require it to be HIPAA-compliant. You acknowledge that the Platform is not HIPAA-compliant and accept all risks associated with violation of this Section 2(e) by you or others using the Platform on your behalf.
<br /><br />
(f)   <u>Data Export Compliance.</u>  As applicable (for example, if you are not a U.S. person or if you are accessing the Platform from overseas), you and those acting on your behalf agree to comply with the data export laws and regulations of the United States and other applicable jurisdictions in using the Platform, and to obtain any permits, licenses and authorizations required for such compliance. 
<br /><br />
<strong>3. Support</strong>
<br />We may provide help documentation, FAQs, training, support services and other online resources to assist in your use of the Platform and Data (excluding COVID-19 Data).  All training and documentation is provided on an &quot;as is&quot; and &quot;as available&quot; basis, and we disclaim all warranties with respect thereto.
<br /><br />
<strong>4. Intellectual Property</strong> 
<br />(a)  <u>Your Rights.</u>  As between you and us, Data submitted to the Platform by you or on your behalf will be owned exclusively by you. You agree that we may make backup copies of the Data as part of our regular practices to secure the integrity of the Platform. As provided in the IR Terms, COVID-19 Data are owned by us and licensed to you.  Please consult the IR Terms for details at <a href="https://immunerace.adaptivebiotech.com/data-terms-of-use" target="_blank">https://immunerace.adaptivebiotech.com/data-terms-of-use</a>.
<br /><br />
(b)  <u>Our Rights.</u>  The Platform (including all source code and documentation relating thereto) is and will remain our sole property. Except as expressly permitted by these Terms, no part of the Platform may be copied, reproduced, distributed, republished, displayed, posted, transmitted, modified or mirrored in any form or by any means. You agree not to access the Platform by any means other than through the interfaces that we make available for your use. Any rights not expressly granted in these Terms are reserved by us. Our trademarks (including Adaptive Biotechnologies, immunoSEQ, Analyzer and immuneRACE) are our property, and you agree not to display or use them in any manner without our express prior written permission.
<br /><br />
<strong>5. Term, Suspension and Termination</strong>
<br />(a) <u>Termination by Adaptive.</u> Unless otherwise agreed in writing with us, we will make Data on the Platform which is owned by you available to you for twelve (12) months after you or an authorized user on your behalf last accesses the Platform to upload Data to it (the &quot;<strong>Data Term</strong>&quot;), although depending on storage availability we may store it for longer periods. Each subsequent data submission will extend the Data Term automatically. Upon written request and at your expense, we will make Data uploaded through your account available to you for download within ninety (90) days following termination.
<br /><br />
(b) <u>Termination by You.</u> You may terminate these Terms as to Data at any time, with or without cause, by emailing us at clientservices@adaptivebiotech.com and ceasing all use of the Platform. If you are a business, institution or other entity, you must also terminate usage of all associated user accounts to terminate these Terms. Termination of the IR Terms must be made in accord therewith.
<br /><br />
(c) <u>Effect of Termination.</u>  Upon any applicable termination, all of your rights under these Terms and/or the IR Terms will immediately terminate. 
<br /><br />
(d)  <u>Suspension.</u>  Prior to termination we may act at our discretion to suspend the right of you and any users authorized by you to access or use any portion of Data stored on the Platform immediately if we determine that: (i) the use of the Platform poses a security risk to the Platform, may adversely impact the Platform or the networks or data of us or a third party, may subject us or any third party to liability, or may be fraudulent; (ii) our trademarks, service marks, trade names, or logos are being used other than as expressly permitted by us in writing; (iii) you are in breach of these Terms; or (iv) if you are a business entity, you ceased to operate in the ordinary course, made an assignment for the benefit of creditors or similar disposition of assets, or have become the subject of any bankruptcy, reorganization, liquidation, dissolution or similar proceeding. If we suspend access, we will retain your Data through the end of the Term or for at least ninety (90) days following our notice of suspension to you, whichever is first. In the event of a suspension, we will reinstate your user account if you cure the suspension issue within thirty (30) days.  If the suspension is not cured, we may terminate these Terms and your access to the Platform. 
<br /><br />
<strong>6. Limited Warranty; Disclaimers and Limitation of Liability</strong><br />
(a) <u>Limited Warranty.</u> With respect to your use of the Platform to access Data or COVID-19 Data, we warrant that the Platform will achieve in all material respects the functionality described on the documentation website accessible at: <a href="http://www.adaptiveagate.com" target="_blank">http://www.adaptiveagate.com.</a>  Your sole and exclusive remedy for any breach of this warranty will be that we will use commercially reasonable efforts to modify that the Platform to achieve in all material respects the functionality described in the documentation, and if Adaptive is unable to restore such functionality after being advised of any deficiency in writing at clientservices@adaptivebiotech.com within sixty (60) days of the first instance of any material functionality problem. The limited warranty set forth in this <u>Section 6</u> is made to and for the benefit of authorized users of the Platform only, and will only apply if the Platform and any related reagents or kits have been utilized in accordance with the documentation, these Terms and applicable law. <strong>Given the inherent biological complexity of our research tools, under no circumstances can we guarantee the outcome or results of any use made of Data accessed on this platform.</strong>
<br /><br />
(b) <u>Disclaimer of Warranties.</u> OTHER THAN THE LIMITED WARRANTY IN <u>SECTION 6(a)</u> ABOVE, ALL EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS, AND WARRANTIES INCLUDING ANY IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, ACCURACY OF INFORMATIONAL CONTENT, OR ARISING FROM A COURSE OF DEALING TRADE PRACTICE, ARE HEREBY EXCLUDED TO THE EXTENT ALLOWED BY APPLICABLE LAW AND ARE EXPRESSLY DISCLAIMED BY US. TO THE EXTENT AN IMPLIED WARRANTY CANNOT BE EXCLUDED THROUGH OPERATION OF LAW, SUCH WARRANTY IS LIMITED IN DURATION TO A 90-DAY WARRANTY PERIOD FOLLOWING YOUR FIRST USE OF THE PLATFORM
<br /><br />
(c) <u>Limitations of Liability.</u> Our maximum aggregate liability in any calendar year to any person, firm or corporation whatsoever arising out of or in the connection with the Platform, whether such liability arises from any claim based on breach or repudiation of contract, negligence, tort, statutory duty, or otherwise, WILL NOT IN ANY CASE exceed the amount of fees you have paid us, if any, during such calendar year. IN NO EVENT WILL ADAPTIVE BE LIABLE TO ANYONE FOR LOST PROFITS OR REVENUE OR FOR INCIDENTAL, CONSEQUENTIAL, PUNITIVE, COVER, SPECIAL, RELIANCE OR EXEMPLARY DAMAGES, OR INDIRECT DAMAGES OF ANY TYPE OR KIND HOWEVER CAUSED, WHETHER FROM BREACH OF WARRANTY, BREACH OR REPUDIATION OF CONTRACT, NEGLIGENCE, OR ANY OTHER LEGAL CAUSE OF ACTION FROM OR IN CONNECTION WITH THESE TERMS, WHETHER OR NOT WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR THEY WERE OTHERWISE FORESEEABLE.
<br /><br />
<strong>7. Dispute Resolution</strong><br />You agree that the following dispute resolution procedures will exclusively govern any dispute, claim or controversy arising out of or relating to your use of the Platform (&quot;<strong>Disputes</strong>&quot;) <em>except</em> claims for equitable remedies related to infringement, misappropriation or ownership of intellectual property. Disputes will be resolved by confidential binding arbitration administered by JAMS in Seattle, Washington before a single arbitrator. The arbitrator selected will be a retired judge who has had experience with technology disputes. The parties agree the arbitrator will not be empowered to award punitive, exemplary or other damages disclaimed or otherwise limited under <u>Section 6</u>, and the parties waive any right to recover any such damages. Each party will bear its own costs in connection with the arbitration, although the arbitrator will award the prevailing party its reasonable costs and attorneys&#39; fees.
<br /><br />
<strong>8. Miscellaneous</strong><br />
(a)    <u>Federal Government Provisions.</u> If you are or work for the US Federal Government (including as a contractor), we provide the Platform, including related software and technology, in accordance with the following terms: government technical data and software rights related to the Platform include only those rights customarily provided to the public as defined in these Terms. This customary license is provided in accordance with FAR 12.211 (Technical Data) and FAR 12.212 (Software) and, for Department of Defense transactions, DFAR 252.227-7015 (Technical Data – Commercial Items) and DFAR 227.7202-3 (Rights in Commercial Computer Software or Computer Software Documentation). If a government agency has a need for rights not conveyed under these terms, it must negotiate with us to determine if there are acceptable terms for transferring such rights, and a mutually acceptable written addendum specifically conveying such rights must be included in any applicable contract or agreement.
<br /><br />
(b)    <u>Governing Law.</u> This Agreement is controlled by and construed under the laws of the State of Washington, notwithstanding conflicts of law provisions, and the state and federal courts located in King County, Washington will have exclusive jurisdiction over any claim arising under these Terms if not addressable by arbitration in accord with Section 7. Arbitration awards and such other judgments may be enforced in any relevant jurisdiction. The parties specifically disclaim the application of the UN Convention on Contracts for the International Sale of Goods. Notwithstanding the foregoing, either party may seek interim injunctive relief in any court of appropriate jurisdiction with respect to any alleged breach of such party&#39;s intellectual property.
<br /><br />
(c)    <u>Entire Agreement.</u> Any separate written contract between an Adaptive customer and us which covers use of the Platform will control to the extent of any direct conflict between these Terms or the IR Terms and such written contract.
<br /><br />
(d)    <u>No Implied Waivers.</u> Either party&#39;s waiver of any term or condition of these Terms or the IR Terms at any time will not be construed to waive such term or condition at subsequent times or any other term or condition, nor as a waiver of its rights to enforce such term or condition.
<br /><br />
(e)  <u>Changes to the Platform; Amendment of Terms.</u> We may make modifications to these Terms, the IR Terms and the Platform from time to time and we will use commercially reasonable efforts to notify you of any material modifications, including the effective date of the change.  <br />
<br />
<br />
    </div>
  );
};

export default TermsOfService;