import * as React from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  route: string,
  iconClass: string
};

const NavigationItem: React.FC<Props>  = ({route, iconClass, children}) => (
  <NavLink
    to={route}
    className='tab d-flex'
    activeClassName='active'
  >
    <i className={iconClass}></i>
    {children}
  </NavLink>
);

export default NavigationItem;
