import { Action } from 'redux';

export const INITIAL_PAYLOAD = 'INITIAL_PAYLOAD';

export interface IInitialPayloadAction extends Action {
  payload: boolean
}

export type InitialPayloadAction = IInitialPayloadAction;

export interface IInitialPayloadState {
  fetched: boolean
}