import * as React from 'react';

export interface IStaticHtmlStateProps {
    path: string
}

const StaticHtml: React.FC<IStaticHtmlStateProps> = ({ path }) => {
    const [html, setHtml] = React.useState({ __html: "" });

    React.useEffect(() => {
        fetch(path)
            .then(result => {
                return result.text();
            })
            .then(html => {
                setHtml(
                    {
                        __html: html
                    }
                );
            });
    }, [path]);

    return (
        <div className="page-container">
            <span dangerouslySetInnerHTML={html} />
        </div>
    );
};


export default StaticHtml;