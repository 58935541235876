import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { app as routes } from '../../routes.json';
import { adbio as adapRoutes } from '../../routes.json';
import LocalePicker, { ILocalePickerStateProps, ILocalePickerDispatchProps } from '../locale/LocalePicker';

export interface IFooterStateProps extends ILocalePickerStateProps {}

export interface FooterDispatchProps extends ILocalePickerDispatchProps {}

const Footer: React.FC<IFooterStateProps & FooterDispatchProps> = (
  { lng, lngs, setLanguage }
) => {
  const { t } = useTranslation();

  return(
    <div className='footer container'>
      <div className='float-left'>
        {t('footer_copyright')}
      </div>
      <div className='float-right'>
        <NavLink to={routes.termsOfService} className='mr-2'>
          {t('common_termsOfUse')}
        </NavLink>
        <a className='mr-2' rel="noopener noreferrer" target="_blank" href={adapRoutes.privacyPolicy}>
            {t('common_privacyPolicy')}
        </a>

        <a className='mr-2' rel="noopener noreferrer" target="_blank" href={adapRoutes.cookiePolicy}>
            {t('common_cookiePolicy')}
        </a>

        <span className='ml-4'>
          <LocalePicker lng={lng} lngs={lngs} setLanguage={setLanguage} />
        </span>
      </div>
    </div>
  );
};

export default Footer;