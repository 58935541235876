import * as React from 'react';
import StaticHtml from "../staticHtml/staticHtml";

export interface IWelcomeStateProps {

}

const Welcome: React.FC<IWelcomeStateProps> = () => {
    return (
        <StaticHtml
            key="welcome"
            path="/pages/welcome.html"/>
    );
};


export default Welcome;