import { Modal } from 'react-bootstrap';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ContactUs from './ContactUs';

export interface ContactUsStateProps {
  show: boolean
}

interface ContactUsDispatchProps {
  close: () => void
}

const ContactUsModal: React.FC<ContactUsStateProps & ContactUsDispatchProps> = ({show, close}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={close} className='contact-us'>
      <Modal.Header closeButton>
        <Modal.Title>{t('common_contactUs')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='text-center'>
          <ContactUs />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-light btn-sm' onClick={close}>
          {t('contactUs_close', {uppercase: true})}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactUsModal;