import * as React from 'react';
import _ from 'lodash';

import { ILocale } from '../../types/locale';

export interface ILocalePickerStateProps {
  lng: string,
  lngs: ILocale[]
}

export interface ILocalePickerDispatchProps {
  setLanguage: (lng: string) => void
}

const LocalePicker: React.FC<ILocalePickerStateProps & ILocalePickerDispatchProps> = (
  { lng, lngs, setLanguage }
) => {
  return(
    <React.Fragment>
      {!!lngs.length && <React.Fragment>
        <div className={`flag ${lng}`} />
        <select
          value={lng}
          onChange={(e) => setLanguage(e.target.value)}
          className='language-selector'
        >
          {_.map(lngs, ({name, locale}: {name: string, locale: string}) => (
            <option key={locale} value={locale}>{name}</option>
          ))}
        </select>
      </React.Fragment>
      }
    </React.Fragment>
  );
};

export default LocalePicker;