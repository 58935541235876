import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import * as routes from '../../routes.json';
import logo from '../../images/adaptive-logo.svg';

export interface IHeaderStateProps {

}

export interface IHeaderDispatchProps {

}

const Header: React.FC<IHeaderStateProps & IHeaderDispatchProps> = ( ) => {
  const { t } = useTranslation();

  return (
    <div className='header'>
      <div className='container'>
        <div className='row'>
          <div className='col-4 primary-offset-color font-family-heading font-size-m product-title'>
            { t('title') }
          </div>
          <div className='col-4 text-center logo'>
            <NavLink to={routes.app.documentation}>
              <img src={logo} alt='Logo' />
            </NavLink>  
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
