import { Action } from 'redux';
import { RequestStatus } from './request';
import { ILocale } from '../../types/locale';

export const GETTING_LOCALES: string = 'GETTING_LOCALES';
export const GOT_LOCALES: string = 'GOT_LOCALES';

export interface ILocalesState {
  lngs: ILocale[],
  status: RequestStatus
}

export interface IGotLocalesAction extends Action {
  payload: ILocale[]
}

export type LocaleActionTypes = Action | IGotLocalesAction;