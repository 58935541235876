import * as React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import NavigationItem from './NavigationItem';
import { app as routes } from '../../routes.json';

import { ErrorStateType } from '../../types/errors';

export interface NavigationStateProps {
  errors: ErrorStateType[]
}

const BLOCKING_ERRORS = [
  ErrorStateType.InvalidTOS
];

const Navigation: React.FC<NavigationStateProps> = (
  { errors}
) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      { _.intersection(errors, BLOCKING_ERRORS).length === 0 ?
        <div className='navigation'>
          <div className='container d-flex'>
            <NavigationItem
                route={routes.welcome}
                iconClass='fas fa-door-open'
            >
              <span>{t('common_welcome')}</span>
            </NavigationItem>

            <NavigationItem
                route={routes.documentation}
                iconClass='fas fa-book'
            >
              <span>{t('common_documentation')}</span>
            </NavigationItem>

            <div>
              <NavigationItem
                route={routes.about}
                iconClass='fas fa-question-circle'
              >
                <span>{t('common_about')}</span>
              </NavigationItem>
            </div>
          </div>
        </div> : null }
    </React.Fragment>
  );
};

export default Navigation;
