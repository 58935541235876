import * as React from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../config.json';

const ContactUs: React.FC = () => {
  const { t } = useTranslation();

  const numbers = config.contact.phone.replace(/\D/g, '');

  return (
    <div>
      <div className='mt-1'>
        {t('contactUs_heading', {uppercase: true})}
      </div>
      <div className='mt-3'>
        <i className='fas fa-phone-alt mr-2 primary-color'></i>
        <a className='font-weight-semi-bold' href={`tel:+${numbers}`}>{config.contact.phone}</a>
      </div>
      <div className='mt-3'>
        <i className='fas fa-envelope mr-2 primary-color'></i>
        <a className='font-weight-semi-bold' href={`mailto:${config.contact.email}`}>{config.contact.email}</a>
      </div>
    </div>
  );
};

export default ContactUs;