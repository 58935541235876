import { connect } from 'react-redux';

import RoutesSwitch, { RoutesSwitchStateProps } from '../components/routes/RoutesSwitch';
import { IApplicationState } from '../redux';

interface RoutesSwitchStateToProps extends Pick<IApplicationState, 'error'> {}

const mapStateToProps = ({ error }: RoutesSwitchStateToProps): RoutesSwitchStateProps => ({
  errors: error.errors
});

export default connect(mapStateToProps)(RoutesSwitch);