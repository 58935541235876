import { connect } from 'react-redux';

import Navigation, { NavigationStateProps } from '../components/navigation/Navigation';
import { IApplicationState } from '../redux';

interface IStateToProps extends Pick<IApplicationState, 'error'> {}

const mapStateToProps = ({
  error }: IStateToProps
): NavigationStateProps => ({
  errors: error.errors
});

export default connect(mapStateToProps)(Navigation);
