import * as React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import TermsOfServiceComponent from './termsOfService/TermsOfService';

const TermsOfService: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='pt-2'>
      <h1 className='text-center primary-color my-2'>{t('title')}</h1>
      <Helmet>
        <title>{t('termsOfUse_title')}</title>
      </Helmet>
      <TermsOfServiceComponent />
    </div>
  );
};

export default TermsOfService;