import { IActionString, IActionOptionalString } from './common';

export interface ICustomMessagesState {
  marketing: string,
  notification?: string,
  help: string
}

export const MARKETING_MESSAGE = 'MARKETING_MESSAGE';
export const NOTIFICATION = 'NOTIFICATION';
export const HELP_CONTENT = 'HELP_CONTENT';

export type CustomMessageActionTypes = IActionString | IActionOptionalString;