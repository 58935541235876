import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { customMessagesReducer } from './reducers/customMessage';
import { contactUsReducer } from './reducers/contactUs';
import { errorReducer } from './reducers/error';
import thunk from 'redux-thunk';
import { localesReducer } from './reducers/locale';
import { initialPayloadReducer } from './reducers/initialPayload';
import { cookiePolicyReducer } from './reducers/cookiePolicy';
import { notificationsReducer } from './reducers/notification';
import { ICustomMessagesState } from './types/customMessage';
import { ContactUsState } from './types/contactUs';
import { ErrorState } from './types/error';
import { ILocalesState } from './types/locale';
import { IInitialPayloadState } from './types/initialPayload';
import { INotificationsState } from './types/notification';
import { ICookiePolicyState } from './types/cookiePolicy';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

export interface IApplicationState {
  contactUs: ContactUsState,
  error: ErrorState,
  customMessages: ICustomMessagesState,
  locales: ILocalesState,
  initialPayload: IInitialPayloadState,
  notifications: INotificationsState,
  cookiePolicy: ICookiePolicyState
}

const rootReducer = combineReducers<IApplicationState>({
  contactUs: contactUsReducer,
  error: errorReducer,
  customMessages: customMessagesReducer,
  locales: localesReducer,
  initialPayload: initialPayloadReducer,
  notifications: notificationsReducer,
  cookiePolicy: cookiePolicyReducer
});

export type AppState = ReturnType<typeof rootReducer>;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);

export default store;