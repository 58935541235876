import ContactUs, { ContactUsStateProps } from '../components/contactUs/ContactUsModal';
import { connect } from 'react-redux';

import { close } from '../redux/actions/contactUs';
import { IApplicationState } from '../redux';

interface IMapStateToProps extends Pick<IApplicationState, 'contactUs'> {}

const mapStateToProps = ({ contactUs }: IMapStateToProps): ContactUsStateProps => ({
  show: contactUs.show
});

const mapDispatchToProps = (dispatch: any) => ({
  close: () => dispatch(close())
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);