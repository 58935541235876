import i18n from 'i18next';

import * as localeStorage from './localStorage';

export const setLanguage = (lng: string, skipApi?: boolean) => {
  if (lng === i18n.language) {
    return;
  }

  localeStorage.setLanguage(lng);
};