import { connect } from 'react-redux';

import Header, { IHeaderDispatchProps, IHeaderStateProps } from '../components/header/Header';

const mapStateToProps = (): IHeaderStateProps => ({

});

const mapDispatchToProps = (dispatch: any): IHeaderDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
