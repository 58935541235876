import produce, {Draft} from 'immer';

import {
  NotificationsActionsType,
  INotificationsState,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  IAddNotificationAction
} from '../types/notification';

const initialNotificationsState: INotificationsState = {
  notifications: []
};

export const notificationsReducer = produce((
  state: Draft<INotificationsState>,
  action: NotificationsActionsType
): void => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      state.notifications.push((action as IAddNotificationAction).payload);
      break;
    case REMOVE_NOTIFICATION:
      state.notifications.shift();
      break;
  }
}, initialNotificationsState);