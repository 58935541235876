import produce, {Draft} from 'immer';

import {
  CookiePolicyActionsType,
  SET_COOKIE_POLICY_HTML,
  ICookiePolicyState
} from '../types/cookiePolicy';

const initialCookiePolicyState: ICookiePolicyState = {
  html: ''
};

export const cookiePolicyReducer = produce((
  state: Draft<ICookiePolicyState>,
  action: CookiePolicyActionsType
): void => {
  switch (action.type) {
    case SET_COOKIE_POLICY_HTML:
      state.html = action.payload;
      break;
  }
}, initialCookiePolicyState);
