import { ErrorState, ErrorActionTypes, SET_ERROR, REMOVE_ERROR } from '../types/error';

const initialState: ErrorState = {
  errors: []
};

export const errorReducer = (
  state = initialState,
  action: ErrorActionTypes
): ErrorState => {
  switch (action.type) {
    case SET_ERROR:
      return Object.assign({}, state, {errors: state.errors.concat(action.payload)});
    case REMOVE_ERROR:
      return Object.assign({}, state, {errors: state.errors.filter(e => e !== action.payload)});
    default:
      return state;
  }
};