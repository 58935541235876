import { connect } from 'react-redux';

import App, { AppStateProps, AppDispatchProps } from '../components/App';
import { IApplicationState } from '../redux';

interface IMapStateToProps extends Pick<IApplicationState, 'initialPayload' | 'notifications'> {}

const mapStateToProps = ({ initialPayload, notifications }: IMapStateToProps): AppStateProps => ({

});

const mapDispatchToProps = (dispatch: any): AppDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(App);
