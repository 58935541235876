import produce, {Draft} from 'immer';

import {
  GETTING_LOCALES,
  GOT_LOCALES,
  ILocalesState,
  LocaleActionTypes,
  IGotLocalesAction
} from '../types/locale';

import { RequestStatus } from '../types/request';

const initialState: ILocalesState = {
  status: RequestStatus.none,
  lngs: []
};

export const localesReducer = produce((
  state: Draft<ILocalesState>,
  action: LocaleActionTypes
): void => {
  switch (action.type) {
    case GETTING_LOCALES:
      state.status = RequestStatus.inProgress;
      break;
    case GOT_LOCALES:
      state.status = RequestStatus.completed;
      state.lngs = (action as IGotLocalesAction).payload;
      break;
  }
}, initialState);
