import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Header from '../containers/Header';
import Navigation from '../containers/Navigation';
import RoutesSwitch from '../containers/RoutesSwitch';
import ContactUs from '../containers/ContactUs';
import Footer from '../containers/Footer';

import '../css/bootstrap-variables.scss';
import '../css/main.scss';

export interface AppStateProps {

}

export interface AppDispatchProps {

}

const App: React.FC<AppStateProps & AppDispatchProps> = () => {
  return (
    <div className='App'>
      <Router>
        <div className='main-container'>
          <Header />
          <Navigation />
          <RoutesSwitch />
        </div>
        <Footer />
        <ContactUs />
      </Router>
    </div>
  );
};

export default App;
