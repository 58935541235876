import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Welcome from '../welcome/Welcome';
import Documentation from '../documentation/Documentation';
import About from '../about/About';
import TermsOfService from '../policies/TermsOfService';
import { app as routes } from '../../routes.json';
import { ErrorStateType } from '../../types/errors';
import {defaultRoute} from "./Routes";
import StaticHtml from "../staticHtml/staticHtml";
import StaticIFrame from "../staticHtml/staticIframe";

export interface RoutesSwitchStateProps {
  errors: ErrorStateType[],
}

const RoutesSwitch: React.FC<RoutesSwitchStateProps> = ({ errors }) => (
  <div className='container'>
    <Switch>
      <Redirect exact from="/" to={defaultRoute()} />
      <Route path={routes.welcome}>
        <Welcome />
      </Route>
      <Route path={routes.documentation}>
        <Documentation />
      </Route>
      <Route exact path={routes.agatepyDocs}>
        <StaticIFrame url={routes.agatepyHostedDocs + "/index.html"}/>
      </Route>
      {/* these routes will try to render agatepy docs in site frame
          but styling isn't quite right yet
      <Route exact path={routes.agatepyDocs}>
          <StaticHtml path={routes.agatepyHostedDocs + "/" + "index.html"}/>
      </Route>
      <Route path={routes.agatepy} render={props =>
          <StaticHtml path={props.location.pathname.replace(routes.agatepy, routes.agatepyHostedDocs)} />
      }/>*/}
      <Route path="/pages/:name" render={props =>
          <StaticHtml path={props.match.params.name + '.html'} />}
      />
      <Route path={routes.about}>
        <About />
      </Route>
      <Route path={routes.termsOfService}>
        <TermsOfService />
      </Route>
    </Switch>
  </div>
);

export default RoutesSwitch;

